import React, { Component } from "react";



const Serverurl= ["https://golegappa.com/appfiles/"]
//const Serverurl= ["http://localhost/xampp/apoorva/isifol/"]



export default Serverurl;






