import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Navigate  } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Serverurl from './Apiurl';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
export default class Myaccount extends Component {
	
	 constructor(props) {
    super(props);
	 this.state = {name:'',phoneno:'',CustomerData:[],btndisabled:false,pagename:'',errormessage:'',open:false,cs_email:'',}
	 }
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val}); 
} 


componentDidMount() {

	document.title = "Isifol - My Account";
	
	if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
            redirect: true,
			pagename:'/Login'
          })
		  }
	this.apicall()

}

apicall(){
	
	 axios.get(Serverurl+"ajaxcustomer.php?action=list&cs_id="+localStorage.getItem("ISIuserid"))
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  CustomerData: resp.data,  
			  cname: resp.data[0].cs_name,
			  phoneno:resp.data[0].cs_phonenumber,
			  role:resp.data[0].cs_role,cs_email:resp.data[0].cs_email,
			  totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }

    });
	
	
}
handleClick= (event) => 
		{
		event.preventDefault();
				
				let cname = this.state.cname;
				let phoneno = this.state.phoneno;
				let cs_email = this.state.cs_email;
					
				if(cname==''){
						
				this.setState({errormessage:"Please Enter Name",open:true});
		 window.scrollTo({        top: 0     })
	return false; 
				}
			
				if(phoneno==''){
						this.setState({errormessage:"Please Enter Phoeno",open:true});
						 window.scrollTo({        top: 0     })
						 return false; 
					
				}
var formData = new FormData();
				formData.append('action','myaccount') 	
				formData.append('cs_id',localStorage.getItem("ISIuserid"))
				formData.append('cs_name',cname)
				formData.append('cs_phonenumber',phoneno)
				
	
		formData.append('cs_email',cs_email)
				axios.post(Serverurl+"ajaxcustomer.php", formData, {
				}).then(res => {
				
				
			
			
					 if(localStorage.getItem("ISIuserid")!=null)
	{
           
		 if(localStorage.getItem("ISIrole")!=null)
		  {  
		   
		   if(localStorage.getItem("ISIrole")=="1")
		  {
		   
		  
		   this.setState({
            redirect: true,
			pagename:'/Admindashboard'
       	   })
	} 
		 
		  
		  else  if(localStorage.getItem("ISIrole")=="2")
		  {
			  
			 
			 
		   this.setState({
            redirect: true,
			pagename:'/Salesdashboard'
          })
			  
			  
		  }
		   else  if(localStorage.getItem("ISIrole")=="3")
		  {
			  
			 
			 
		   this.setState({
            redirect: true,
			pagename:'/Userdashboard'
          })
			  
			  
		  }
		  
		  
		  }	  
	}		  
            });
				
				
			
				
				
			
		}
				
	 handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }
	render() {
		if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
return (
<React.Fragment>
<div className="contentinner">

	
	
		
			 <p>  <Collapse in={this.state.open}>
							<Alert
							  action={
								<IconButton
								  aria-label="close"
								  color="inherit"
								  size="small"
								  onClick={() => {
								   this.handleErrorclose();
								  }}
								>
								  <CloseIcon fontSize="inherit" />
								</IconButton>
							  } severity="error"
							>
							{this.state.errormessage}
							</Alert>
					</Collapse></p>
			<p><span className="validation">*</span>Name </p>		
			<p><TextField id="cname" fullWidth  variant="standard" name="cname" value={this.state.cname}  inputProps={{tabIndex: "1",maxLength:150}} onChange={this.myChangeHandler} /></p>
			<p><span className="validation">*</span>Phone Number </p>
			<p>
			 <TextField id="phoneno" fullWidth  variant="standard" name="phoneno" value={this.state.phoneno}  inputProps={{tabIndex: "2",maxLength:12}} onChange={this.myChangeHandler} />
</p>
<p>
			 <TextField id="cs_email" fullWidth  variant="standard" name="cs_email" value={this.state.cs_email}  inputProps={{tabIndex: "3",maxLength:150}} onChange={this.myChangeHandler} />
</p>
			
			
			<Button variant="contained" className="frmPrimarybtn" onClick={this.handleClick}>
Submit
</Button>
			
		


</div>
</React.Fragment>
)

	}		
}