import React, { Component,createRef } from "react";

import { Navigate   } from 'react-router-dom';

import { makeStyles } from '@mui/styles'; 

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';

import Serverurl from './Apiurl';
import axios from 'axios';import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SignatureCanvas from 'react-signature-canvas';


import RefreshIcon from '@mui/icons-material/Refresh';


import save from './images/Save.jpg';
const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },textField: {
    width: '100%',
  },root: {  
    width: '100%',  
  },container: {  
    maxHeight: 440,  
  }, 
 
}));

export default class  OrderConfirm extends Component {
	constructor(props) {
    super();

    this.state = {redirect:false,pagename:'',subName:'',modconatct:0,subEmail:'',subPhone:'',btnview:false,erroropen:false,alerttype:"error",errormessage:"",Orderdata:[],Proddata:[],btndisabled:false,btntext:'Confirm Order' ,orderno:'',orderdate:"",rowsPerPage:5,rowsPaging:[],singature:'',
	};
	
	  this.signatureCanvasRef = createRef();

	
	}
	componentDidMount() {
		
		if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
           redirect: true,
			pagename:'/Login'
          })
		  }
		  if(localStorage.getItem("ISIsession")===null)
		  {
		  if(localStorage.getItem("ISIrole")==="2")
		  {
		  this.setState({
           redirect: true,
			pagename:'/Salesdashboard'
          })
		  } else if(localStorage.getItem("ISIrole")==="3")
		  {
		  this.setState({
           redirect: true,
			pagename:'/Product'
          })
		  }
		  }else{
			    if(localStorage.getItem("ISIORid")!==null)
		  {
			  
		  this.ApiOrder();
		  }else{
			 this.setState({
           redirect: true,
			pagename:'/Product'
          })  
		  }
		  }
	} handleProdErrorclose=(event)=>{
	  
	this.setState({erroropen: false});  
  }
	ApiOrder()
	{
	axios.get(Serverurl+'ajaxorder.php?action=list&or_sessionid='+localStorage.getItem("ISIsession"))
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({Orderdata:resp.data,orderno:resp.data[0]["or_orderno"],orderdate:resp.data[0]["or_createddate"] });	
	this.apicall();	
	}
  })
	}
	
	

  







apicall(){
	
 axios.get(Serverurl+"ajaxorderproduct.php?action=cartlist&sessionid="+localStorage.getItem("ISIsession"))

  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  Proddata: resp.data,  
			   totaldata:parseInt(resp.data[0].count),
			   

		    });
		
			
			
	 
     }
	 else{
		   this.setState({Proddata:[],totaldata:0});
	 }
 
    });
}
CartUpdate() { 




let r = window.confirm("Do you really want to Update?");
 if(r == true){
var studArray = this.state.Proddata;
let strsubdata = "",strsubuser="";
 var subnewRow = studArray.map((rowssub, j) => { 

	 
strsubdata=strsubdata+strsubuser+rowssub.op_id+"~"+rowssub.op_quantity+"~"+rowssub.op_pr_id;
strsubuser=";";

 })
 
 var formData = new FormData();
     formData.append('action','update')
	   formData.append('sessionid',localStorage.getItem("ISIsession"))
	    formData.append('pr_title',strsubdata)
		 formData.append('userid',localStorage.getItem("ISCustid"))
		 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
    
            this.apicall();
   
    
            });
 }
}
Changeaddress(){
	
	this.setState({redirect:true,pagename:'/Myaddresslis?type=2'});
	
}
myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  
  const re = /^[0-9\b]+$/;
  
  if(nam==="subPhone"  )
  {if (event.target.value === '' || re.test(event.target.value)) {
	  this.setState({[nam]: val}); 
  }
  }
 
  else{
	  
	   this.setState({[nam]: val});
  }

  
 
  
}
onClickConfirm= (event) => {
	   event.preventDefault();
	   
	 let dataURL = this.signatureCanvasRef.current.toDataURL();
     let parts = dataURL.split(',');
         let base64Image = parts[1];
		 
		 
	   
	   const remail= /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
	    let subName = this.state.subName;
	let subEmail = this.state.subEmail; 
	let subPhone = this.state.subPhone,
	Ormessage=this.state.Ormessage,
	 modconatct=this.state.modconatct;   
	    if(subName==="")
  {	  
    this.setState({ errormessage:'Please Enter Name',erroropen:true});
    window.scrollTo({
				  top: 0
			  })
  return false;
 
  } if(subEmail==="")
  {	  
    this.setState({ errormessage:'Please Enter  Email id',erroropen:true});
 
   window.scrollTo({
				  top: 0
			  }) 
			  return false;
  }
  
   else  
  {
 if (!remail.test(subEmail)) {
	 this.setState({errormessage:"Please Enter Valid Email Id",erropen:true});
		 window.scrollTo({        top: 0     })
	return false; 
  }
	  
  }
  
  if(subPhone==="")
  {	  
    this.setState({ errormessage:'Please Enter Phone No.',erroropen:true});
  
   window.scrollTo({
				  top: 0
			  })
			  return false;
			  
  }var formData = new FormData();
  formData.append('action','update') 
	  formData.append('or_cs_id',localStorage.getItem("ISCustid")) 
	   formData.append('userid',localStorage.getItem("ISIuserid"))
	   formData.append('or_submittedname',subName)
formData.append('or_submittedemailid',subEmail)	
formData.append('or_submittedphoneno',subPhone)
formData.append('or_instruction',Ormessage)	

formData.append('or_id', localStorage.getItem("ISIORid"))
formData.append('or_modeofcaontact',modconatct)
formData.append('signaturebaseimage',base64Image)
formData.append('op_sessionid',localStorage.getItem('ISIsession'))

	axios.post(Serverurl+"ajaxorder.php", formData, {
        }).then(res => {
			if(res.data!=="")
			{
				this.ApiOrder();
	  this.setState({alerttype:"success",btndisabled:true, btnview:true,errormessage:'Thank you for making order, your order has been places successfully ',erroropen:true},()=>{
		  setTimeout(() => {
	 
	
	
		   localStorage.removeItem("ISIsession")
  localStorage.removeItem("ISCustName")
 localStorage.removeItem("ISIORid")
  localStorage.removeItem("ISIbiladrsid")
   localStorage.removeItem("ISIshipadrsid")
		  },600); 
	  });

   window.scrollTo({
				  top: 0
			  })
			  
			}
	});
	
	
}
	Productqty= (firstrow) => event  => { 
	 let val = event.target.value;
const updatedArray = [...this.state.Proddata];


		updatedArray[firstrow].op_quantity = val;
			  

 this.setState({Proddata: updatedArray,});
}





  ClearSignature = () => {
    if (this.signatureCanvasRef.current) {
      this.signatureCanvasRef.current.clear();
    }
  };
  
  clearSignaturesave = () => {
  let dataURL = this.signatureCanvasRef.current.toDataURL();

let parts = dataURL.split(',');

let base64Image = parts[1];

console.log('Base64 Image Data:', base64Image);
console.log('Metadata:', parts[1]); 
  }
	render() {

let totalnetamount=0;


if (this.state.redirect === true) {
      return <Navigate to={this.state.pagename} />
    }
	
	
	
	
	
	
return (






					
  <React.Fragment>
  <Collapse in={this.state.erroropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleProdErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity={this.state.alerttype}
        >
        {this.state.errormessage}
        </Alert>
      </Collapse>
   <table width="100%"  cellpadding="10" cellspacing="0">
    <tr><td >
<h4><b>
  #{this.state.orderno}</b></h4>
</td><td className="order-date">

  {this.state.orderdate}
</td></tr>
     <tr><td colSpan="2" >




</td></tr>
   </table>
   <div className="proddetails">
<h2>Product info</h2>
</div>
  <table border="1"  cellpadding="10" cellspacing="0">
  
  


<tr>
<th>Product</th><th>Quantity</th>
<th>Price</th>

<th>Net Price</th>

</tr>

{this.state.Proddata.map((item,idx) => {
	

 totalnetamount=Number(item.netprice)+Number(totalnetamount)	
	
 return ( 	
 <React.Fragment>	




<tr>
<td>{item.op_pr_title}</td><td valign='middle' className="prodcart">

{this.state.btnview===false && ( <React.Fragment>	

<TextField
                               ref="op_quantity"
                              name="op_quantity"
							  className="counters"
                              value={item.op_quantity}              
                                    
                            onChange={this.Productqty(idx)}/> &nbsp;&nbsp;&nbsp;

	<p className="secondarybtn" onClick={()=>{this.orderDelete(item.op_pr_id)}}>Delete{<DeleteIcon className="iconvertical"/>}</p>
	
	
 </React.Fragment>	)}{this.state.btnview===true && (
	<p> {item.op_quantity}</p>
)}
</td>
<td align="right">${item.op_price}</td>

<td align="right">${item.netprice}</td>

</tr>


</React.Fragment>
);
					})}
					<tr><td colSpan={2} align="right">
					{this.state.btnview===false && (
					<p className="addtocart" onClick={()=>{this.CartUpdate()}}><span className="btnupdate">Update <img src={save} className="iconvertical"/></span></p>
					)}
					</td><td>Total Price</td><td align="right">${(totalnetamount).toFixed(2)}</td></tr>

</table>


<table width="80%" cellpadding="5">
{this.state.Orderdata.map((item,idx) => {
 return ( 	
 <React.Fragment>	
 

 <tr><td colspan="2">
</td>

</tr>

 <tr><td colspan="2">
<div className="proddetails">
<h2>Shipping Details <p className="addtocart" onClick={()=>{this.Changeaddress()}}><span className="btnupdate"> <ModeEditOutlineOutlinedIcon className="iconvertical"/>Change Address </span></p></h2>
</div></td>

</tr>
<tr>
<td colspan="2"><p>{item.or_shippingaddress},<br/>
	{item.or_shippingcity}, {item.or_shippingstate},<br/>
	
	{item.or_shipcountry} - {item.or_shippingpincode}</p></td></tr>
	{this.state.btnview===true && (<React.Fragment>
	<tr><td colspan="2">
	<div className="proddetails">
<h2>Order Submitter Details</h2></div>
</td></tr>
	<tr>
<td colspan="2">

<p>{item.or_submittedname}<br/>
  {item.or_submittedemailid}
  <br/>
  {item.or_submittedphoneno}<br/>
  <b>How do you like to contact</b>: {item.modeofcaontact}
  
  </p></td></tr>
	
	
	</React.Fragment>)}
	
	
</React.Fragment>
);
})}</table>

{this.state.btnview===false && (

<div className="orderform"><div className="proddetails">
<h2>Order Submitter Details</h2></div>
<ul>
<li><p><span className="validation">*</span>Name</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.subName}
            fullWidth
            id="subName"   onChange={this.myChangeHandler}
          placeholder="Order Submitted By"
            name="subName" 
           inputProps={{maxLength:150, tabIndex: "1"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span> Email Id</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.subEmail}
            fullWidth
            id="subEmail"   onChange={this.myChangeHandler}
          placeholder="Order Submitted Email Id"
            name="subEmail" 
           inputProps={{maxLength:150, tabIndex: "2"  }} 
           
          /></p>
</li>
<li><p><span className="validation">*</span> Phone No.</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.subPhone}
            fullWidth
            id="subPhone"   onChange={this.myChangeHandler}
          placeholder="Order Submitted Phone No."
            name="subPhone" 
           inputProps={{maxLength:15, tabIndex: "3"  }} 
           
          /></p>
</li>
<li>
<p><span className="validation">*</span>How do you like to contact</p>
		<p><FormControl  className="stadrp">			   
		<Select
		variant="outlined"
		native
		value={this.state.modconatct}
		onChange={this.myChangeHandler}
		name='modconatct'
		inputProps={{tabIndex: "4"}}
		InputLabelProps={{
		shrink: true,
		}}
		>
		<option value={0}>Select</option>
		<option value={1}>Phone</option>
		<option value={2}>Email</option>		
		
		</Select>
		</FormControl></p>	
		</li>
 <li ><p>Instructions</p><p>
<TextField
            variant="outlined"
            margin="normal"
            value={this.state.Ormessage}
            fullWidth
            id="Ormessage"   onChange={this.myChangeHandler}
          placeholder="Instructions"
            name="Ormessage" 
           inputProps={{tabIndex: "5"  }} 
           multiline rows="2"
          /></p>
</li>


<li>

        <div>
		<p>Signature</p>
        <SignatureCanvas
		
		  ref={this.signatureCanvasRef}

          penColor='green'
          canvasProps={{ width: 400, height: 128, className: 'signature-canvas',}}
        />
		<span className="refreshicon"><RefreshIcon  onClick={this.ClearSignature} /></span>
       
      </div>
</li>


<li className="stadrp"><Button variant="contained" disabled={this.state.btndisabled} className="secondarybtn" onClick={this.onClickConfirm}>
				{this.state.btntext}
</Button>
</li>

</ul>


<div className="clear"></div>
</div>
)}
</React.Fragment>

)
	
	
	
}
	 
}


