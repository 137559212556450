import React ,{Component} from 'react';
import { makeStyles } from '@mui/styles';  
import { Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import PropTypes from 'prop-types';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ordericon from './images/create-order-icon.jpg';
import save from './images/Save.jpg';
import delet from './images/delete.jpg';
import view from './images/view.jpg';
import Productactions from './Productactions';
import Productpop from './Productpop';
import cart from './images/cart.jpg';
import Addcart from './images/cart-icon.jpg';
import TextField from '@mui/material/TextField';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Generatesession from './GenerateSession';
const options1 = {
    loop: false,
  
   
    autoplay: true,
	nav:true,
	responsive : {
    // breakpoint from 0 up
    0 : {
       items:1,
       
    },
    500 : {
        items:3,
    },
    800 : {
        items:5,
    },
	1001 : {
        items:4,
    },
	}
  };
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}





const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Userdashboard extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',ordercount:'0',prodid:'',erroropen:false,alerttype:"error",errormessage:"",StoreData:[],Productdata:[],StorerecentData:[],StoreadminData:[],qty:props.min,min:1,qty:1      }
	 
	 	  this.updateParentProduct = this.updateParentProduct;
	 };
    
   updateParentProduct = (text) => {  this.apicall();  }
 myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  




componentWillMount() {
	
	
	if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'Login'
          })
		  }
	
	document.title = "Baawarchi - Dashboard";
	
	 if(localStorage.getItem("ISIsession")==null){
			   let strguid = Generatesession();
			
			 localStorage.setItem("ISIsession",strguid);
		 }
 
  this.apicall(); 

}



apicall(){
	
	 axios.get(Serverurl+"ajaxdashboard.php?action=list&userid="+localStorage.getItem("ISIuserid"))
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
			  StoreData: resp.data,  
			  ordercount: resp.data[0].ordercount,
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }

    });

	this.recentData();
	
}
Productaddbox(val){
        this.setState({ prodid: val });  

         setTimeout(() => this.refs.Productpop.handleClickOpen(),500);

      }
  latestproducts(){
	  
	 axios.get(Serverurl+'ajaxdashboard.php?action=listlatestprod&userid='+localStorage.getItem("ISCustid")+'&sessionid='+localStorage.getItem("ISIsession"))
  .then(resp => {  
		if(resp.data.length>0){
		
			this.setState({  
			  Productdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
    
	
  
    }); 
	  
	  
  }
  recentData(){
	  
	 axios.get(Serverurl+'ajaxdashboard.php?action=listrecentprod&userid='+localStorage.getItem("ISCustid")+'&sessionid='+localStorage.getItem("ISIsession"))
  .then(resp => {  
		if(resp.data.length>0){
		
			this.setState({  
			   Productdata: resp.data,  
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }else{
    	this.latestproducts();
	 }
  
    }); 
	  
	  
  }
  
   handleChange = (e) => {
        let value = (e.target.value ? parseInt(e.target.value) : 0);
        value = this.validateValue(value);
        this.setState({qty: value});
    }
    
addtoCart(id,firstrow){
	let self=this;
	const updatedArray = [...this.state.Productdata];
	let qty=updatedArray[firstrow].qty;

	var formData = new FormData();
	  formData.append('action','Create')
	  
	  formData.append('op_pr_id',id)
	   formData.append('op_sessionid',localStorage.getItem("ISIsession"))
	 formData.append('op_quantity',qty)
	 
	    formData.append('userid',localStorage.getItem("ISIuserid"))
		 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
			
			let strqty=parseFloat(localStorage.getItem("IScartqty"))+parseFloat(1);
			  localStorage.setItem("IScartqty",strqty)
			  this.props.parentdasCallback(
            strqty
        );
			  
			   //self.props.parentmainCallback(     strqty );
   this.setState({alerttype:'success', errormessage:'Product Added to Cart',erroropen:true},()=>{this.recentData();}); 
             window.scrollTo({
				  top: 0
			  })
    
            });		
	
}
Productqty= (firstrow) => event  => { 
	 let val = event.target.value;
const updatedArray = [...this.state.Productdata];


		updatedArray[firstrow].qty = val;
			  

 this.setState({Productdata: updatedArray,});
}

orderDelete(prid){  
	
let r = window.confirm("Do you really want to Delete?");
 if(r == true){ //sign Out logic
 var formData = new FormData();
     formData.append('action','delete')
	 formData.append('pr_id',prid)
	  formData.append('sessionid',localStorage.getItem("ISIsession"))
formData.append('userid',localStorage.getItem("ISIuserid"))	
	
	
	 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
    let strqty=parseFloat(localStorage.getItem("IScartqty"))-parseFloat(1);
			  localStorage.setItem("IScartqty",strqty)
			  this.props.parentdasCallback(
            strqty
        );
         this.setState({alerttype:'info', errormessage:'Product has been deleted from cart',erroropen:true},()=>{ this.recentData();}); 
         
    window.scrollTo({
				  top: 0
			  })
    
            });
 }
      }
	
  handleProdErrorclose=(event)=>{
	  
	this.setState({erroropen: false});  
  }	
render() {
	
if (this.state.logredirect === true) {
      return <Navigate to={this.state.pagename} />
    }
  
  	 
    
  return (
  

   
 
  
    <React.Fragment>
   
  <Productpop ref="Productpop" pdid={this.state.prodid} updateDoneprod={this.updateParentProduct}/>
  <Collapse in={this.state.erroropen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleProdErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity={this.state.alerttype}
        >
        {this.state.errormessage}
        </Alert>
      </Collapse>

    <div className="dashboard">


<h2>Welcome to Baawarchi</h2>
		        

                     {stableSort(this.state.StoreData, getSorting(this.state.orderBy, this.state.order)).map((item,index) => {  
           return ( <React.Fragment>


<ul>
<li>

<div className="count"><h3>{item.catcount}</h3><p>CATEGORIES</p></div>
<div className="clear"></div>

</li>
<li>

 
<div className="count"><h3><a href="#/product">{item.prodcount}</a></h3><p><a href="#/product">PRODUCTS</a></p></div>
<div className="clear"></div>

</li>
{item.ordercount ==='0' &&(<React.Fragment><li className="counts">

<div className="count"><h3><a href="#/product"><img src={ordericon}/></a></h3><p><a href="#/product">Create your first order</a></p></div>
<div className="clear"></div>
 </li></React.Fragment>)}
 {item.ordercount !=='0' &&(<React.Fragment> <li>

<div className="count"><h4><b>{item.ordercount}</b></h4><p>
	{item.ordercount==="1" && (
<React.Fragment>
ORDER
</React.Fragment>
	)}{item.ordercount!=="1" && (
<React.Fragment>
ORDERS
</React.Fragment>
	)}
</p>
<div className="orderbtn"><a href="#/product"><img src={cart}  style={{"width":"20px"}}className="iconvertical"/> Order Now</a></div>

</div>
<div className="clear"></div>
</li> </React.Fragment>)}

</ul>




				</React.Fragment>	
				);
					})}
                     

                   
   


   </div> 
   
   
   
   
{this.state.ordercount ==='0' &&(<React.Fragment>   
   
 <div className="dashboards">

 
 
<h2 className="headertext">Latest addition to our collection</h2>
	<ul>	        
   
                     {this.state.Productdata.map((item,index) => {  
           return ( <React.Fragment>



<li className="gridprod">
{item.stock ==='Out of Stock' &&(<React.Fragment><p className="bgclr">{item.stock}</p>
</React.Fragment>)}
{item.stock !=='Out of Stock' &&(<React.Fragment>
<p className="bgclrs">{item.stock}</p>
</React.Fragment>)}
<div className="prodimg">
<a href={"#/Productview?pdid="+item.pr_id}>
{item.prdimg!=="" && (
<img src={Serverurl+"uploads/"+item.prdimg+""} />
	)}
{item.prdimg==="" && (
<img src={Serverurl+"uploads/noimg.jpg"} />
	)}
	</a>
<h2><a href={"#/Productview?pdid="+item.pr_id}>{item.prodtitle}</a></h2></div>
<div className="prodinfo"><h4>$ {item.price}</h4>
{item.pr_stockqty !=='0' &&(<React.Fragment> 
 
                 <div className="prodcart">
  

 <TextField
                               ref="qty"
                              name="qty"
							  className="counters"
                              value={item.qty}              
                                    
                            onChange={this.Productqty(index)}/>
 
 
 </div>
              

{item.ordqty !=="0" &&(
<ul className="innericons">

<li onClick={()=>{this.addtoCart(item.pr_id,index)}}><img src={save} />
<p>Update</p>
</li>
<li onClick={()=>{this.orderDelete(item.pr_id)}}><img src={delet} />
<p>Delete</p></li>
<li onClick={()=>{this.Productaddbox(item.pr_id)}}><img src={view}/><p> View</p></li>

</ul>
)}
{item.ordqty ==="0" &&(
<ul className="carticons">

<li onClick={()=>{this.addtoCart(item.pr_id,index)}}><img src={Addcart} />
<p>Add to Cart</p>
</li>

<li onClick={()=>{this.Productaddbox(item.pr_id)}}  ><img src={view}/><p>Quick View</p></li>

</ul>
)}
</React.Fragment>)}

{item.pr_stockqty ==='0' &&(<React.Fragment> 

<ul className="carticons nostock">
<li onClick={()=>{this.Productaddbox(item.pr_id)}} className="cusrsoor"><img src={view}/><p>Quick View</p></li>

</ul>

</React.Fragment>)}
</div>
</li>







				</React.Fragment>	
				);
					})}
                     

                 
 </ul> 

 
 


 </div>

 

</React.Fragment>)}
{this.state.ordercount !=='0' &&(<React.Fragment>  
<div className="dashboards">


 
 

<h2 className="headertext">Recently Purchased</h2>
	<ul>	        

                     {this.state.Productdata.map((item,index) => {  
           return ( <React.Fragment>



<li className="gridprod">
{item.stock ==='Out of Stock' &&(<React.Fragment><p className="bgclr">{item.stock}</p>
</React.Fragment>)}
{item.stock !=='Out of Stock' &&(<React.Fragment>
<p className="bgclrs">{item.stock}</p>
</React.Fragment>)}
<div className="prodimg"><a href={"#/Productview?pdid="+item.pr_id}>{item.prdimg!=="" && (
<img src={Serverurl+"uploads/"+item.prdimg+""} />
)}
{item.prdimg==="" && (
<img src={Serverurl+"uploads/noimg.jpg"} />
	)}
	</a>
<h2><a href={"#/Productview?pdid="+item.pr_id}>{item.prodtitle}</a></h2></div>
<div className="prodinfo"> <h4>$ {item.price}</h4>
{item.pr_stockqty !=='0' &&(<React.Fragment> 
 
                 <div className="prodcart">
  

 <TextField
                               ref="qty"
                              name="qty"
							  className="counters"
                              value={item.qty}              
                                    
                            onChange={this.Productqty(index)}/>
 
 
 </div>
              

{item.ordqty !=="0" &&(
<ul className="innericons">

<li onClick={()=>{this.addtoCart(item.pr_id,index)}}><img src={save} />
<p>Update</p>
</li>
<li onClick={()=>{this.orderDelete(item.pr_id)}}><img src={delet} />
<p>Delete</p></li>
<li onClick={()=>{this.Productaddbox(item.pr_id)}}><img src={view}/><p> View</p></li>

</ul>
)}
{item.ordqty ==="0" &&(
<ul className="carticons">

<li onClick={()=>{this.addtoCart(item.pr_id,index)}}><img src={Addcart} />
<p>Add to Cart</p>
</li>

<li onClick={()=>{this.Productaddbox(item.pr_id)}}  ><img src={view}/><p>Quick View</p></li>

</ul>
)}
</React.Fragment>)}
{item.pr_stockqty ==='0' &&(<React.Fragment> 

<ul className="carticons nostock">
<li onClick={()=>{this.Productaddbox(item.pr_id)}} className="cusrsoor"><img src={view}/><p>Quick View</p></li>

</ul>

</React.Fragment>)}

</div>
</li>







				</React.Fragment>	
				);
					})}
                     

                   
 </ul> 


   </div>
 
   </React.Fragment>)}
 </React.Fragment>
		
	  
	 


  );
  }	
   
   


}