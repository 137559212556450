import React ,{Component} from 'react';
import { makeStyles } from '@mui/styles';  
import { Navigate  } from 'react-router-dom';
import axios from 'axios';
import Serverurl from './Apiurl';
import PropTypes from 'prop-types';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}


function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


let strCust=[{title:'',year:0}];


const useStyles = makeStyles({  
  root: {  
    width: '100%',  
  },  
  container: {  
    maxHeight: 440,  
  }, 

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },  
});


export default class Salesdashboard extends Component {
	
	
	constructor(props) {
    super();
	
	 this.state = {
logredirect:false,pagename:'',StoreData:[],cartcount:0,custtags:'',cartdata:[],strCustdeflt:[],autoCustname:'',StoreadminData:[],cname:'' ,open:false,errormessage:''     }
this.onTagsChange = this.onTagsChange.bind(this);
  this.escFunction = this.escFunction;
	 };
    
   
 myChangeHandler = (event) => {
  let nam = event.target.name;
  let val = event.target.value;
  this.setState({[nam]: val});

 }  

 onInputChange = (event, newTextValue) => {

if(newTextValue!==""){


  this.setState({autoCustname:newTextValue})
}

  
 }
 onTagsChange = (event, values) => {
	 console.log(values)
if(values!==null){
    this.setState({
      custtags: values["year"]
    }, () => {
      // This will output an array of objects
      // given by Autocompelte options property.
     
    });
}else{
	this.setState({custtags:'',autoCustname:'' },()=>{this.admindata();});
}
  }
  onloadCustomerBind(){
	 
	  

	    axios.get(Serverurl+'ajaxcustomer.php?action=autoextender')
  
  .then(resp => { 
 this.setState({strCustdeflt:[]},()=>{
	 
	 var rowsArray = resp.data;
	 rowsArray.map((row, i) => {
			 
		 const items = {
        title:row.title,
          year:row.year
          
        };
		
         strCust.push(items)
		 
		 });
		  this.setState({strCustdeflt:strCust})
	 
 })
  
   
   });
  
  }
ApiOrderProduct(){
	if(localStorage.getItem("ISCustid")!==null)
	{
	 axios.get(Serverurl+"ajaxorderproduct.php?action=orderbyuser&op_sessionid="+localStorage.getItem("ISIsession")+'&userid='+localStorage.getItem("ISCustid"))
  .then(resp => {  
		
    if(resp.data.length>0){
		
			this.setState({  
		
			  cartcount: resp.data[0].cartcount,
			 cartdata:resp.data
			});			
     }

    });
	}
	
}
componentDidMount() {
	
	
	if(localStorage.getItem("ISIuserid")==null)
		  {

		   this.setState({
            logredirect: true,
			pagename:'/Login'
          })
		  }
	
	document.title = "Dashboard";
	
	document.addEventListener("keydown", this.escFunction, false);
 this.onloadCustomerBind();
 this.ApiOrderProduct();
 
this.admindata();
}
componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
	
  }
handleClickSearch=()=>{   
 
  if(this.state.custtags==="")
	  {
				  this.setState({errormessage:"Please Enter Customer Name/Email/Phone",open:true});
				 window.scrollTo({
				  top: 0
			  })
				 return false;
	  }
  this.admindata(); 

	
} 

 escFunction= (event) => {
	  var self=this; 
	   let nam = event.target.name;
        let val = event.target.value;
		
 this.setState({[nam]: val});
 
  if(event.keyCode === 13) {
	  
	  if(val==="")
	  {
				  this.setState({errormessage:"Please Enter Customer Name/Email/Phone",open:true});
				 window.scrollTo({
				  top: 0
			  })
				 return false;
	  }
	 	setTimeout(() => this.admindata() ,500);	
  }
	  
 }

    admindata(){
	  
	 axios.get(Serverurl+'ajaxdashboard.php?action=listadmin&cs_name='+this.state.custtags)
  .then(resp => {  
		if(resp.data.length>0){
		
			this.setState({  
			   StoreadminData: resp.data,  
			   csid: resp.data[0].cs_id,
			   totaldata:parseInt(resp.data[0].count)
			});
			
	  
	 
     }
    
	
  
    }); 
	  
	  
  }
  
onClickCustomerSel(csid,csname) 
		{
			if(this.state.cartcount===0)
			{
				
			
			localStorage.setItem("ISCustName",csname);
			localStorage.setItem("ISCustid",csid);
				this.setState({ logredirect:true,pagename:'/Product' });
			}else{
				
				let r = window.confirm("Product already in cart, Do you really want to change price?");
 if(r == true){
	  localStorage.setItem("ISCustName",csname);
			localStorage.setItem("ISCustid",csid);
	 var studArray = this.state.cartdata;
let strsubdata = "",strsubuser="";
 var subnewRow = studArray.map((rowssub, j) => { 

	 
strsubdata=strsubdata+strsubuser+rowssub.op_id+"~"+rowssub.op_quantity+"~"+rowssub.op_pr_id;
strsubuser=";";

 })
 
 var formData = new FormData();
     formData.append('action','update')
	   formData.append('sessionid',localStorage.getItem("ISIsession"))
	    formData.append('pr_title',strsubdata)
		 formData.append('userid',localStorage.getItem("ISCustid"))
		 axios.post(Serverurl+"ajaxorderproduct.php", formData, {
        }).then(res => {
    localStorage.removeItem("ISIshipadrsid")
	localStorage.removeItem("ISIbiladrsid")
           localStorage.removeItem("ISIORid")
				this.setState({ logredirect:true,pagename:'/Product' });
   
    
            });
	 	
 }
				
			}
		}	

handleErrorclose=(event)=>{
	  
	this.setState({open: false});  
  }		
render() {
	
if (this.state.logredirect === true) {
      return <Navigate to={this.state.pagename} />
    }
  
  	 
    
  return ( <React.Fragment>
   <style>{"\
        .float-sm{display:none;}\
      "}</style>
   
    
  
     
  <div className="dashboardsales">

 
  <div className="salessearchsec">
  <p>  <Collapse in={this.state.open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
               this.handleErrorclose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error"
        >
        {this.state.errormessage}
        </Alert>
      </Collapse></p>
<p>


 <Autocomplete
      
	    inputValue={this.state.autoCustname}
		  onInputChange={this.onInputChange}
         id="catauto"
      
        options={strCust}

		 value={this.state.custtags}
 

    
	  onChange={this.onTagsChange}  
        renderInput={params => (
        <TextField {...params} onFocus={this.escFunction}  variant="outlined"


		  />
      
        )}  getOptionLabel={(option) => option.title || ""}
	
      />
</p>
				</div>
	<ul>	        

                     {stableSort(this.state.StoreadminData, getSorting(this.state.orderBy, this.state.order)).map((item,index) => { 
					 let strcust=item.cs_name;
if(strcust.length>25)
{
 strcust=item.cs_name.substring(1, 25)+"...";	
} 
           return ( <React.Fragment>



<li><div className="salessec">
<h4>{strcust}</h4>


<p>{item.cs_phonenumber}</p>

<h3> {item.totorders} Orders</h3>
{item.ordate !=='' &&(<React.Fragment><p><EventRepeatIcon/> <span>{item.ordate}</span></p></React.Fragment>)}
</div>
<p className="nextbtn arrow" onClick={()=>{this.onClickCustomerSel(item.cs_id,item.cs_name)}}>Next</p>

</li>







				</React.Fragment>	
				);
					})}
                     

                   
 </ul> 
 <div className="clear"></div>
</div>
 
   
 </React.Fragment>
		
		  
	 


  );
  }	
   
   


}